import { Transition } from '@headlessui/react'
import Image from 'next/image'
import { useContext, useEffect, useState } from 'react'
import QuizContext from '../context/QuizContext'
import BackgroundShapes from './BackgroundShapes'
import QuizNav from './QuizNav'

export default function Background({
  showNav,
  children,
  position,
  quiz_uuid = false,
  question = false,
  is_host = false,
  is_live = false,
  quizStatus = false,
  pin = false,
  backgroundImage = false,
  mobileBackgroundImage = false,
  logo = false,
  adTakeoverUrl = false,
  sendToStartle = false,
  stopCastingToStartle = false,
  startleId = false
}) {
  const { quizData, setQuizData } = useContext(QuizContext)
  const { userCorrect } = quizData

  const [hasQuizUuid, setHasQuizUuid] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('bg-blue-500')

  useEffect(() => {
    if (userCorrect === true) setBackgroundColor('bg-green-500')
    if (userCorrect === false) setBackgroundColor('bg-red-500')
    if (userCorrect === null && backgroundImage && mobileBackgroundImage)
      setBackgroundColor('bg-transparent')
    else if (userCorrect === null) setBackgroundColor('bg-blue-500')
  }, [userCorrect])

  useEffect(() => {
    if (quiz_uuid) setHasQuizUuid(true)
  }, [quiz_uuid])

  return (
    <>
      <div className="bg-blue-500 flex flex-col flex-1 relative">
        {userCorrect === null && !backgroundImage && !mobileBackgroundImage && (
          <BackgroundShapes />
        )}

        {backgroundImage && (
          <div className="hidden md:block">
            <div className="fixed w-full h-full">
              <Image src={backgroundImage} layout="fill" objectFit="cover" />
            </div>
          </div>
        )}
        {mobileBackgroundImage && backgroundImage && (
          <div className="block md:hidden">
            <div className="fixed w-full h-full">
              <Image
                src={mobileBackgroundImage}
                layout="fill"
                objectFit="cover"
              />
            </div>
          </div>
        )}
        <Transition
          show={userCorrect !== null}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          as="div"
        >
          <div
            className={`print:hidden absolute w-full h-full inset-0 ${backgroundColor}`}
          ></div>
        </Transition>
        {hasQuizUuid && showNav && (
          <QuizNav
            is_host={is_host}
            is_live={is_live}
            quiz_uuid={quiz_uuid}
            question={question}
            quizStatus={quizStatus}
            pin={pin}
            logo={logo}
            ad_takeover_url={adTakeoverUrl}
            sendToStartle={sendToStartle}
            stopCastingToStartle={stopCastingToStartle}
            startleId={startleId}
          />
        )}
        <div
          className={`z-1 flex flex-col flex-1 w-full text-neutral-800 ${
            position ? `md:justify-center justify-${position}` : ''
          }`}
        >
          {children}
        </div>
      </div>
    </>
  )
}
