import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import MemoBackArrow from './icons/BackArrow'

export default function DialogPopupWithBack({
  isOpen = false,
  children,
  closeModal,
  openModal
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
        <div className="min-h-screen px-10 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block">
              <div className=" max-w-lg transform  flex justify-start ">
                <button
                  className=" m-4 text-white flex items-center "
                  onClick={closeModal}
                >
                  <MemoBackArrow className="w-[30px] h-[30px] text-white" />
                  <span>Back</span>
                </button>
              </div>
              <div className=" md:max-w-lg w-full md:w-auto p-20 md:p-30  my-8 overflow-hidden text-center align-middle transition-all transform bg-white text-neutral-800 shadow-xl rounded-lg ">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
