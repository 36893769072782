import * as React from 'react'

export function BackArrow(props) {
  return (
    <svg {...props} viewBox="0 0 30 30" fill="none">
      <path
        d="M17.2566 9.41082C17.582 9.73626 17.582 10.2639 17.2566 10.5893L12.8458 15.0001L17.2566 19.4108C17.582 19.7362 17.582 20.2639 17.2566 20.5893C16.9312 20.9147 16.4035 20.9147 16.0781 20.5893L11.0781 15.5893C10.7526 15.2639 10.7526 14.7362 11.0781 14.4108L16.0781 9.41082C16.4035 9.08539 16.9312 9.08539 17.2566 9.41082Z"
        fill="currentColor"
      />
    </svg>
  )
}

const MemoBackArrow = React.memo(BackArrow)
export default MemoBackArrow
