import React from 'react'

function Cast({ casting = false, props }) {
  return (
    <>
      <svg
        width="35"
        height="20"
        viewBox="0 0 35 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.4726 18.5241C6.91472 19.082 6.0157 19.0875 5.46461 18.5364C4.91351 17.9853 4.91902 17.0863 5.4769 16.5284C6.03478 15.9706 6.93379 15.9651 7.48489 16.5162C8.03598 17.0673 8.03048 17.9663 7.4726 18.5241ZM11.5674 18.6907C11.7703 18.6895 11.9342 18.5187 11.9243 18.3163C11.7598 14.9324 9.06479 12.2411 5.68477 12.0767C5.4823 12.0669 5.31156 12.2308 5.31032 12.4336L5.30379 13.5005C5.30266 13.6865 5.44515 13.8414 5.63064 13.8525C8.06887 13.9987 10.0027 15.9376 10.1485 18.3704C10.1597 18.5559 10.3145 18.6984 10.5005 18.6972L11.5674 18.6907ZM15.1103 18.6438C15.3106 18.6426 15.4732 18.4759 15.4674 18.2758C15.3132 12.949 11.0501 8.68781 5.72529 8.53367C5.52515 8.52788 5.35845 8.69049 5.35724 8.8908L5.35072 9.95648C5.34955 10.1477 5.50014 10.3026 5.69118 10.309C10.058 10.4564 13.5446 13.942 13.692 18.3099C13.6984 18.5009 13.8533 18.6515 14.0446 18.6503L15.1103 18.6438Z"
          fill={casting ? '#EA722A' : '#3D435E'}
          className={`${casting ? 'animate-pulse' : ''}`}
        />
        <path
          d="M6 6.5V4C6 2.34315 7.34315 1 9 1H26C27.6569 1 29 2.34315 29 4V15C29 16.6569 27.6569 18 26 18H17.5"
          stroke="#3D435E"
          strokeWidth="2"
        />
      </svg>
    </>
  )
}

export default Cast
