import { motion } from 'framer-motion'
import * as React from 'react'

const random = (min, max, append = '') =>
  Math.floor(Math.random() * (max - min)) + min + 1 + append

function BackgroundShapes() {
  return (
    <div className="print:hidden">
      <motion.div
        className="w-[40vmax] h-[40vmax] rounded-full bg-[#2589E2] fixed"
        initial={{
          left: '-10%'
        }}
        animate={{
          left: ['-10%', '12%', '-2%', '3%'],
          top: ['90%', '-1%']
        }}
        transition={{
          yoyo: Infinity,
          duration: 60,
          ease: 'easeIn'
        }}
      />
      <motion.div
        className="w-[30vmax] h-[30vmax] rounded-full bg-[#2589E2] fixed"
        initial={{
          right: '-10%'
        }}
        animate={{
          right: ['-8%', '15%', '-3%', '6%'],
          bottom: ['50%', '-1%', '92%']
        }}
        transition={{
          yoyo: Infinity,
          duration: 55,
          ease: 'easeIn'
        }}
      />
      <motion.div
        className="w-[4vmax] h-[4vmax] rounded-full border-4 border-[#2589E2] fixed"
        animate={{
          right: [
            random(25, 40, '%'),
            random(25, 40, '%'),
            random(25, 40, '%'),
            random(25, 40, '%')
          ],
          bottom: [random(0, 5, '%'), random(90, 100, '%')]
        }}
        transition={{
          yoyo: Infinity,
          duration: 60,
          ease: 'easeIn'
        }}
      />
      <motion.div
        className="w-[4vmax] h-[4vmax] rounded-full border-4 border-[#2589E2] fixed"
        animate={{
          left: [
            random(25, 40, '%'),
            random(25, 40, '%'),
            random(25, 40, '%'),
            random(25, 40, '%')
          ],
          top: [random(0, 5, '%'), random(90, 100, '%')]
        }}
        transition={{
          yoyo: Infinity,
          duration: 60,
          ease: 'easeIn'
        }}
      />
      <motion.div
        className="w-[4vmax] h-[4vmax] bg-[#2589E2] fixed"
        animate={{
          left: [random(20, 80, '%'), random(20, 80, '%'), random(20, 80, '%')],
          top: [random(20, 80, '%'), random(20, 80, '%'), random(20, 80, '%')],
          rotate: [
            random(180, 360, 'deg'),
            random(0, 360, 'deg'),
            random(1, 360, 'deg')
          ]
        }}
        transition={{
          yoyo: Infinity,
          duration: 60,
          ease: 'easeIn'
        }}
      />
      <motion.div
        className="w-[5vmax] h-[5vmax] bg-[#2589E2] fixed"
        animate={{
          left: [random(20, 80, '%'), random(20, 80, '%'), random(20, 80, '%')],
          top: [random(20, 80, '%'), random(20, 80, '%'), random(20, 80, '%')],
          rotate: [
            random(180, 360, 'deg'),
            random(0, 360, 'deg'),
            random(1, 360, 'deg')
          ]
        }}
        transition={{
          yoyo: Infinity,
          duration: 60,
          ease: 'easeIn'
        }}
      />
      <motion.div
        className="w-[3vmax] h-[3vmax] fixed"
        animate={{
          left: [random(20, 80, '%'), random(20, 80, '%'), random(20, 80, '%')],
          top: [random(20, 80, '%'), random(20, 80, '%'), random(20, 80, '%')],
          rotate: [random(1, 360, 'deg'), random(1, 360, 'deg')]
        }}
        transition={{
          yoyo: Infinity,
          duration: 60,
          ease: 'easeIn'
        }}
      >
        <svg
          className="w-full h-full"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.36931 0.956686L7.23396 7.49431L0.27501 5.74256L5.36931 0.956686Z"
            fill="#2589E2"
          />
        </svg>
      </motion.div>
      <motion.div
        className="w-[3vmax] h-[3vmax] fixed"
        animate={{
          right: [
            random(10, 50, '%'),
            random(10, 50, '%'),
            random(10, 50, '%')
          ],
          top: [random(10, 50, '%'), random(10, 80, '%'), random(10, 50, '%')],
          rotate: [random(1, 360, 'deg'), random(1, 360, 'deg')]
        }}
        transition={{
          yoyo: Infinity,
          duration: 60,
          ease: 'easeIn'
        }}
      >
        <svg
          className="w-full h-full"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.36931 0.956686L7.23396 7.49431L0.27501 5.74256L5.36931 0.956686Z"
            fill="#2589E2"
          />
        </svg>
      </motion.div>
    </div>
  )
}

const MemoBackgroundShapes = React.memo(BackgroundShapes)
export default MemoBackgroundShapes
