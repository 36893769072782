import React from 'react'

export default function LogoLive(props) {
  const fill = props.fill || '#38403F'
  return (
    <svg
      className="mx-auto w-full max-w-[200px]"
      {...props}
      viewBox="0 0 309 89"
      fill="none"
    >
      <path
        d="M40.7025 64.8874L32.4297 36.8809L44.4646 34.7574L47.649
      48.2286L48.478 43.0195L49.6689 37.1304L58.7894 35.1087L62.3375
      47.7158L64.6043 35.7573L76.6393 33.6338L70.3403 62.1491L59.1796
      64.2421L55.1826 53.3978L51.9797 62.7904L40.7025 64.8874Z"
        fill={fill}
      />
      <path
        d="M79.6177 34.1159L80.1364 25.1284L93.6285 22.9541L93.1098 31.9415L79.6177 34.1159ZM81.6572 64.5329L81.0091 44.7209L77.0898 45.7094L77.8142 36.3717L93.2299 34.1301L94.2168 62.3911L81.6572 64.5329Z"
        fill={fill}
      />
      <path
        d="M96.2484 62.8842L96.009 56.0275L106.066 42.3924L95.9549 44.4486L95.6113 34.611L120.027 31.9658L120.283 39.2993L110.658 51.097L121.091 50.7326L121.422 60.2125L96.2484 62.8842Z"
        fill={fill}
      />
      <path
        d="M123.368 61.47L123.128 54.6135L133.186 40.9781L123.074 43.0345L122.73 33.1968L147.147 30.5518L147.403 37.8853L137.777 49.6827L148.21 49.3185L148.541 58.7985L123.368 61.47Z"
        fill={fill}
      />
      <path
        d="M150.812 62.308L149.471 23.9112L170.948 21.4581C174.756 21.3251 177.722 22.1567 179.847 23.9529C181.972 25.7492 183.094 28.3564 183.213 31.7747C183.281 33.7225 182.99 35.3843 182.338 36.76C181.724 38.1347 180.868 39.2391 179.768 40.0733C181.346 40.6948 182.603 41.5862 183.539 42.7474C184.473 43.8688 184.98 45.5823 185.061 47.8877C185.186 51.5047 184.159 54.3662 181.975 56.4722C179.793 58.578 176.679 59.7014 172.639 59.8426L150.812 62.308ZM161.899 37.6688L168.092 36.6009C170.345 36.5222 171.437 35.4494 171.365 33.3825C171.33 32.3888 171.012 31.6438 170.411 31.1474C169.848 30.6099 168.944 30.3629 167.702 30.4063L161.684 31.468L161.899 37.6688ZM162.411 52.2762L168.836 51.2002C171.206 51.1174 172.352 49.9632 172.275 47.7372C172.202 45.6703 170.942 44.6797 168.494 44.7651L162.186 45.837L162.411 52.2762Z"
        fill={fill}
      />
      <path
        d="M187.022 28.5096L187.539 19.522L201.032 17.3477L200.514 26.3351L187.022 28.5096ZM189.061 58.9266L188.412 39.1145L184.494 40.1031L185.218 30.7653L200.633 28.5237L201.621 56.7848L189.061 58.9266Z"
        fill={fill}
      />
      <path
        d="M217.991 56.7864C214.027 56.9248 211.137 56.0307 209.319 54.1045C207.499 52.1383 206.529 49.4461 206.41 46.0277L206.119 37.6807L202.68 37.8008L203.159 30.1598L205.792 28.3199L206.427 22.6713L218.029 19.416L218.337 27.0302L223.523 25.9529L223.499 35.3258L218.664 36.391L218.915 44.815C218.982 46.723 220.063 47.6403 222.162 47.567C223.329 47.5264 224.5 46.4347 225.652 45.9966L225.926 55.0765C224.817 55.5929 223.623 55.9928 222.349 56.2759C221.075 56.5592 219.623 56.7294 217.991 56.7864Z"
        fill={fill}
      />
      <path
        d="M1.41799 71.3785L0 26.5957L14.0573 24.3018L14.4643 41.4425L22.4714 29.6584L37.3199 26.3575L25.1778 45.9779L40.7481 68.1533L26.827 70.4943L16.5575 55.9992L15.0475 59.4474L15.4884 68.1565L1.41799 71.3785Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M270.41 73.1821L270.315 73.1788C270.331 73.1793 270.346 73.1799 270.362 73.1804C270.379 73.181 270.394 73.1815 270.41 73.1821ZM307.941 38.1379L271.631 36.8699L272.899 0.55957C292.953 1.25986 308.641 18.0842 307.941 38.1379ZM235.32 35.602C235.32 35.6191 235.318 35.6361 235.318 35.6533L235.32 35.602Z"
        fill="#F8B246"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M307.941 40.3181C307.24 60.3718 290.416 76.0609 270.362 75.3607L271.63 39.0502L235.32 37.7822C235.32 37.7659 235.32 37.7497 235.322 37.7334L235.32 37.7822L307.941 40.3181Z"
        fill="#CE477C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M268.143 73.1801L268.226 73.183C268.212 73.1826 268.198 73.1819 268.184 73.1815C268.17 73.181 268.157 73.1806 268.143 73.1801ZM233.143 35.6031L269.454 36.871L270.721 0.560646C250.667 -0.139645 233.843 15.5494 233.143 35.6031ZM305.763 38.139C305.763 38.1589 305.762 38.1785 305.762 38.1982L305.763 38.139Z"
        fill="#915EF6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M270.589 2.73535L270.851 2.74448C270.807 2.74281 270.764 2.74121 270.719 2.73969C270.676 2.73816 270.632 2.73672 270.589 2.73535ZM233.142 37.7823L269.451 39.0504L268.184 75.3605C248.13 74.6604 232.441 57.8359 233.142 37.7823ZM305.762 40.3181C305.762 40.2987 305.764 40.2793 305.764 40.26L305.762 40.3181Z"
        fill="#2589E2"
      />
      <circle cx="270.539" cy="37.8811" r="37.8811" fill="#915EF6" />
      <path
        d="M293.895 29.9549L288.642 32.4269C288.003 32.7274 287.596 33.3697 287.596 34.0755V41.7919C287.596 42.5036 288.01 43.1502 288.657 43.4474L293.91 45.8621C295.117 46.417 296.493 45.5351 296.493 44.2066V31.6035C296.493 30.268 295.103 29.3863 293.895 29.9549Z"
        fill="white"
      />
      <rect
        x="248.779"
        y="26.9775"
        width="36.1131"
        height="21.8069"
        rx="3.54997"
        fill="white"
      />
      <path
        d="M182.073 82.2455H188.9V85.9424H177.837V67.3502H182.073V82.2455ZM193.104 71.4519C192.421 71.4519 191.836 71.227 191.35 70.7772C190.865 70.3095 190.622 69.7608 190.622 69.1312C190.622 68.4836 190.865 67.9349 191.35 67.4852C191.836 67.0354 192.421 66.8106 193.104 66.8106C193.788 66.8106 194.363 67.0354 194.831 67.4852C195.299 67.9169 195.533 68.4656 195.533 69.1312C195.533 69.7608 195.29 70.3095 194.804 70.7772C194.336 71.227 193.77 71.4519 193.104 71.4519ZM191.107 85.9424V73.3407H195.101V85.9424H191.107ZM206.609 73.3407H210.953L205.61 85.9424H202.129L196.787 73.3407H201.131L203.883 80.8424L206.609 73.3407ZM225.056 79.3852C225.056 79.5111 225.039 79.9879 225.003 80.8154H215.774C216.188 82.1286 217.168 82.7852 218.715 82.7852C219.938 82.7852 221 82.3535 221.899 81.49L223.95 84.0805C223.338 84.7461 222.529 85.2768 221.522 85.6725C220.532 86.0683 219.507 86.2662 218.445 86.2662C216.449 86.2662 214.811 85.6546 213.534 84.4313C212.275 83.19 211.645 81.6069 211.645 79.682C211.645 77.6852 212.302 76.0751 213.615 74.8519C214.946 73.6286 216.547 73.0169 218.418 73.0169C220.271 73.0169 221.836 73.6106 223.114 74.7979C224.409 75.9852 225.056 77.5143 225.056 79.3852ZM215.747 78.4678H221.09C221.072 77.8561 220.811 77.3614 220.307 76.9836C219.822 76.5879 219.219 76.39 218.499 76.39C217.042 76.39 216.125 77.0826 215.747 78.4678Z"
        fill={fill}
      />
    </svg>
  )
}
